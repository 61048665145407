import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBrDzYItSBLIZaS7CLSokUf7ME_HCrZ9go",
  authDomain: "questions-and-answers-d89ed.firebaseapp.com",
  projectId: "questions-and-answers-d89ed",
  storageBucket: "questions-and-answers-d89ed.appspot.com",
  messagingSenderId: "174331425005",
  appId: "1:174331425005:web:5854ee303c3f3bf7f7dce4",
  measurementId: "G-LD22YBXFFQ"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };